.main-container {
  display: flex;
}

.main {
  width: 100%;
  padding: 2rem;
  background-color: rgb(243, 244, 246);
}

.heading {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1rem 0;
}

.loading-container {
  text-align: center;
  height: 100px;
}

.table-container-main {
  border: 1px solid silver;
  border-radius: 1rem;
  overflow-x: auto;
  background-color: white;
}

.filter-container {
  padding: 1rem;
  display: flex;
  justify-content: right;
}

.search-bar-container {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: white;
  border: 1px solid silver;
  border-radius: 8px;
  padding: 5px;
  width: 30%;
}

.search-bar-container > img {
  width: 20px;
  object-fit: contain;
}

.search-bar-container > input {
  width: 100%;
  outline: none;
}

.table {
  width: 100%;
  text-align: center;
}

.table > thead {
  background-color: #f7f8f9;
}

.table th {
  color: rgb(75 85 99);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 15px 5px;
  white-space: nowrap;
}

.table th:first-child,
.table td:first-child {
  padding-left: 20px;
}

.table th:last-child,
.table td:last-child {
  padding-right: 20px;
}

.table td {
  padding: 15px 5px;
  border-top: 1px solid #e4e4e4;
  white-space: nowrap;
}

.table tr:last-child {
  border-bottom: 1px solid #e4e4e4;
}

.no-answer-container {
  text-align: center;
  padding: 1rem;
}

.load-button-container {
  padding: 12px;
  display: flex;
  justify-content: right;
}

.load-button-container > button {
  background-color: #f7f8f9;
  cursor: pointer;
  border-radius: 8px;
  color: #4b5563;
  padding: 0.2rem 0.5rem;
  border: 1px solid #e4e4e4;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

@media (max-width: 800px) {
  .main-container {
    flex-direction: column;
  }

  .main {
    height: 90vh;
  }

  .filter-container {
    padding: 1rem;
    display: block;
  }

  .search-bar-container {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: white;
    border: 1px solid silver;
    border-radius: 8px;
    padding: 5px;
    width: 100%;
  }
}
