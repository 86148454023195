.main-container {
  display: flex;
  position: relative;
}

.apiloading-container{
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1000;
  backdrop-filter: blur(2px);
}

.main {
  width: 85%;
  padding: 1rem;
  background-color: rgb(243, 244, 246);
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.heading-right > button {
  cursor: pointer;
  border-radius: 8px;
  padding: 10px 15px;
  border: 1px solid grey;
}

.heading {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
}

.heading2 {
  font-weight: 600;
  font-size: 1.2rem;
  margin: 1rem;
  position: sticky;
  left: 16px;
}

.chart-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  margin: 1rem 0;
}

.line-chart {
  background-color: white;
  border: 1px solid silver;
  border-radius: 12px;
  padding: 1rem;
  width: 100%;
  overflow-y: hidden;
}

.table-container {
  background-color: white;
  border-radius: 1rem;
  padding-top: 1rem;
  text-align: left;
  border: 1px solid silver;
  overflow-x: auto;
  margin: 1rem;
}

.table-container > table {
  width: 100%;
}

.table-container thead {
  background-color: #f7f8f9;
  padding: 0 1rem;
}

.table-container th {
  color: rgb(75 85 99);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 15px 5px;
  white-space: nowrap;
}

.table-container td {
  padding: 15px 5px;
  border-top: 1px solid silver;
  white-space: nowrap;
}

.table-container th:first-child,
.table-container td:first-child {
  padding-left: 20px;
}

.table-container th:last-child,
.table-container td:last-child {
  padding-right: 20px;
}

.overlay {
  position: fixed;
  inset: 0;
  z-index: 1;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.089);
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-container {
  background-color: white;
  width: 40rem;
  border-radius: 10px;
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menu-container-wrapper {
  width: 100%;
  border-bottom: 2px solid var(--Neutral-White-300, #EFEFEF);
}

.menu-container {
  display: flex;
  align-items: center;
  transform: translateY(2px);
}

.iframe-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.iframe-container > h1 {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2rem;
  margin-bottom: 5px;
}

.contact-button {
  text-align: left;
  background: linear-gradient(to right, #240037 0%, #9405e4 100%);
  cursor: pointer;
  border-radius: 0.375rem;
  color: white;
  padding: 0.2rem 0.5rem;
  width: fit-content;
}

.share-caption {
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 500;
}

.share-link-container {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: rgb(243 244 246);
}

.share-link-container > p {
  width: 100%;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: auto;
  color: rgb(55 65 81);
}

.social-share-container-main {
  margin-top: 1rem;
}

.social-share-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.close-btn {
  width: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.close-btn > img {
  width: 100%;
  object-fit: contain;
}

.loading-container {
  height: 100px;
}

.no-answer-container {
  text-align: center;
  padding: 1rem;
}

@media (max-width: 1300px)  {
  .chart-container {
    flex-direction: column;
  }

  .main {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .main {
    width: 100%;
  }

  .main-container {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .overlay {
    padding: 1rem;
  }

  .share-container {
    width: 100%;
  }
}

@media (min-width: 0px) and (max-width: 800px) {  
  .menu-container > p {
    display: none;
  }

  .menu-container > img {
    padding: 10px 16px;
    cursor: pointer;
    border-bottom: 2px solid;
    width: 60px;
  }
}

@media (min-width: 801px){  
  .menu-container > p {
    padding: 10px 16px;
    cursor: pointer;
    border-bottom: 2px solid;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .menu-container > img {
    display: none;
  }
}
