.main {
  width: 15%;
  background-color: white;
  position: sticky;
  top: 0;
  bottom: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-container {
  border-bottom: 1px solid silver;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
  gap: 10px;
}

.header-container-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  width: 100%;
}

.header-container img {
  width: 50px;
  max-width: 50px;
  object-fit: cover;
  border-radius: 50%;
  height: 50px;
}

.mobile-header-container {
  display: none;
}

.account-dropdown-main {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: 100ms;
}

.account-dropdown-main:hover {
  opacity: 0.5;
}

.header-container-inner P {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 110px;
}

.account-dropdown {
  position: absolute;
  border: 1px solid silver;
  top: 60px;
  right: 0;
  padding: 7px 20px;
  border-radius: 10px;
  background-color: white;
}

.account-dropdown > p {
  cursor: pointer;
  font-size: 0.875rem;
}

.manage-container {
  border-bottom: 1px solid silver;
  padding: 15px;
  position: relative;
}

.project-dropdown-main {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.project-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  width: 100%;
}

.project-name-container > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 110px;
}

.project-name-container > img {
  width: 30px;
  object-fit: contain;
  background-color: black;
  border-radius: 50%;
}

.project-dropdown {
  position: absolute;
  top: 50px;
  border: 1px solid silver;
  left: 0;
  top: 5px;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  overflow: hidden;
  max-height: 300px;
  overflow-y: scroll;
}

.project-dropdown-name-container {
  padding: 7px 20px;
  cursor: pointer;
}

.project-dropdown-name-container:hover {
  background-color: hsl(0, 0%, 96%);
}

.title {
  color: rgb(107, 114, 128);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 2rem;
  margin: 1rem 0;
}

.resource-container-main {
  padding: 15px;
  padding-top: 0px;
}

.page-container,
.resource-container {
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
}

.page-name-container,
.resource-title-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 10px;
}

.selected-route {
  background: #793FDF;
  color: white;
  fill: white;

}

.hamburger-container {
  width: 35px;
}

.hamburger-container > img {
  width: 100%;
  object-fit: contain;
}

.hamburger-container {
  display: none;
}

@media (max-width: 1300px) {
  .main {
    width: 20%;
  }
}

@media (max-width: 800px) {
  .main {
    background-color: white;
    min-width: none;
    max-width: none;
    width: 100%;
    position: static;
    height: auto;
  }

  .header-container-inner {
    width: fit-content;
  }

  .header-container-inner > p, .svg-container {
    display: none;
  }

  .mobile-header-container {
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;
  }

  .mobile-header-container > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    min-width: 10px;
  }

  .hamburger-container {
    display: block;
  }

  .account-dropdown {
    right: 10px;
    left: 10px;
  }

  /* .project-dropdown {
    right: 10px;
    left: 10px;
  } */

  .project-name-container > p {
    width: 100%;
  }
}
