.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 4rem 8%;
  margin: auto;
  text-align: center;
}

.footer-left h1 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.footer-right {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.icon-container,
.link-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 600px) {
  .footer {
    flex-direction: column;
  }
}
