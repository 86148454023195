.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f4f6;
  flex-direction: column;
  padding: 1rem;
}

.heading-container {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  justify-content: center;
}

.heading-container > img {
  object-fit: contain;
  width: 60px;
}

.heading-container > h1 {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
}

.heading-container > h1 > span {
  font-weight: normal;
}

.form-container {
  background-color: white;
  padding: 1.5rem;
  border-radius: 5px;
  width: 450px;
}

.form-container > h3 {
  line-height: 1.25rem;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  text-align: center;
}

.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
  gap: 5px;
  font-weight: 600;
  color: #475569;
  font-size: medium;
  border: 1px solid silver;
  padding: 10px;
  border-radius: 5px;
}

a:has(.google-button:disabled) {
  cursor: not-allowed;
}

.google-button:disabled {
  cursor: not-allowed;
}

.divider-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #cbd5e1;
}

.divider-container > p {
  color: #cbd5e1;
  font-size: medium;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.action-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.action-title {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: #4b5563;
}

.action-container > input {
  padding: 10px;
  border-radius: 8px;
  outline-color: rgb(201, 201, 201);
  background-color: #f1f1f1;
}

.submit-button {
  padding: 10px;
  border-radius: 8px;
  background: linear-gradient(to right, #240037 0%, #9405e4 100%);
  color: white;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-container {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  padding: 1rem;
}

.otp-box {
  width: 400px;
  background-color: rgba(255, 255, 255, 0.678);
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid silver;
  text-align: center;
}

.otp-input {
  width: 100% !important;
  height: 50px;
  border-radius: 7px;
  border: 1px solid #ccc;
  margin-left: 8px;
  margin-right: 8px;
  background: white;
  font-size: 20px;
}

@media (max-width: 500px) {
  .form-container {
    width: 100%;
  }
}
