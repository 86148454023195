.main-container {
  display: flex;
}

.main {
  width: 85%;
  padding: 2rem;
  padding-top: 5rem;
  display: flex;
  justify-content: space-between;
  background-color: rgb(243, 244, 246);
  gap: 1rem;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.image-container {
  display: flex;
  gap: 2rem;
  margin: 1rem 0;
}

.image-container-left {
  position: relative;
  width: 100px;
}

.delete-icon {
  position: absolute;
  bottom: 0;
  right: -15px;
  object-fit: contain;
  width: 25px;
  cursor: pointer;
}

.profile-picture {
  max-width: 100px;
  width: 100px;
  border-radius: 50%;
  height: 100px;
  object-fit: cover;
}

.image-container-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
  flex-grow: 1;
  overflow: hidden;
}

.image-url-input {
  border: 1px solid silver;
  border-radius: 0.375rem;
  padding: 5px;
  outline: none;
  width: 50%;
}

.image-file-input {
  font-size: small;
  width: fit-content;
}

.form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 1.5rem;
  width: 60%;
  transition: 100ms;
  height: fit-content;
}

.form-container > h1 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  padding-bottom: 1.2rem;
}

.input-container > p {
  color: rgb(75 85 99);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  display: flex;
}

.input-container input {
  outline: none;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid rgb(209 213 219);
  padding: 12px;
}

.form > button {
  background: linear-gradient(to right, #240037 0%, #9405e4 100%);
  font-weight: bold;
  cursor: pointer;
  border-radius: 0.375rem;
  color: white;
  padding: 0.5rem 1rem;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 5px;
}

.form > button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.overlay {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container {
  background-color: rgba(255, 255, 255, 0.678);
  padding: 1rem;
  border-radius: 10px;
}

.loading-container > h1 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
}

.input-field-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.input-field-container > button {
  width: 100px;
  cursor: pointer;
  border-radius: 0.375rem;
  padding: 12px;
  background-color: #9405e4;
  color: white;
  overflow-y: hidden;
  white-space: nowrap;
}

.input-field-container > button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.error-text {
  position: absolute;
  bottom: 0;
  color: red;
  font-size: 0.775rem;;
}

@media (max-width: 1300px) {
  .main {
    width: 80%;
  }
}

@media (max-width: 1100px) {
  .form-container {
    width: 70%;
  }

  .main {
    flex-direction: column;
  }
}

@media (max-width: 900px) {
  .form-container {
    width: 70%;
  }
}

@media (max-width: 800px) {
  .main-container {
    flex-direction: column;
  }

  .main {
    width: 100%;
    height: fit-content;
  }

  .form-container {
    width: 100%;
  }
  
  .image-url-input {
    width: 100%;
  }
}
