.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 8%;
  margin: auto;
}

.nav-logo {
  object-fit: contain;
  width: 50px;
}

.links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.links-container > a {
  color: #333;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.links-container button {
  border-radius: 8px;
  background: linear-gradient(to right, #240037, #9405e4);
  color: white;
  padding: 5px 2rem;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5rem 8%;
  margin: auto;
  gap: 10rem;
}

.hero-left {
  width: 55%;
}

.hero-left > h1 {
  color: #141414;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 88px;
  letter-spacing: -1.28px;
}

.hero-left span {
  background: linear-gradient(90deg, #b45ff1 0%, #9605e7 28.13%, #240037 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-left > p {
  color: #545454;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
  text-transform: capitalize;
  margin: 1rem 0;
}

.hero-right {
  width: 45%;
}

.hero-right > img {
  width: 100%;
  object-fit: contain;
}

.hero-button-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.hero-button-container > a:first-child > button {
  border-radius: 8px;
  background: linear-gradient(to right, #240037, #9405e4);
  color: white;
  padding: 10px 2rem;
}

.hero-button-container > a:last-child > button {
  border-radius: 8px;
  padding: 10px 2rem;
  border: 2px solid silver;
}

.chart-section {
  background-color: #f2e5ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 8%;
  margin: auto;
  gap: 10rem;
}

.chart-left {
  width: 45%;
}

.chart-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chart-right > h4 {
  color: #240037;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.chart-right > h1 {
  color: #141414;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
}

.chart-right > h1 > span {
  background: linear-gradient(91deg, #b45ff1 0%, #9605e7 21.15%, #240037 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.chart-right > p {
  color: #545454;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.referral-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 8%;
  margin: auto;
  gap: 20rem;
}

.referral-left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.referral-left > h4 {
  color: #240037;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.referral-left > h1 {
  color: #141414;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
}

.referral-left > h1 > span {
  background: linear-gradient(91deg, #b45ff1 0%, #9605e7 21.15%, #240037 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.referral-left > p {
  color: #545454;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.referral-right {
  width: 45%;
}

.referral-right > img {
  width: 100%;
  object-fit: contain;
}

.submission-section {
  background-color: #f2e5ff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 5rem 8%;
  margin: auto;
}

.submission-section > h4 {
  color: #240037;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.submission-section > h1 {
  color: #141414;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
}

.submission-section .span-purple {
  background: linear-gradient(91deg, #b45ff1 0%, #9605e7 21.15%, #240037 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.submission-section .span-grey {
  color: #545454;
  font-weight: 500;
}

.submission-section .span-grey-bold {
  font-weight: bold;
}

.submission-section > p {
  color: #374151;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 40%;
}

.submission-section > img {
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid silver;
  box-shadow: 2px 3px 15px silver;
}

.demo-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 5rem 8%;
  margin: auto;
}

.demo-section > h4 {
  color: #240037;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.demo-section > h1 {
  color: #141414;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
}

.demo-section > h1 > span {
  background: linear-gradient(91deg, #b45ff1 0%, #9605e7 21.15%, #240037 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.demo-section > p {
  color: #545454;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.integration-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 5rem 8%;
  margin: auto;
  text-align: center;
  background-color: #3a1a4b;
  color: white;
}

.integration-section > h4 {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.integration-section > h1 {
  color: #fff;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  width: 50%;
}

.integration-section > h1 > span {
  font-weight: 600;
}

.integration-section > a {
  display: flex;
  width: 291px;
  padding: 24px 44px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: linear-gradient(to right, #240037, #9405e4);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.integration-properties-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.integration-property {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 1500px) {
  .hero-left > h1 {
    font-size: 50px;
    line-height: 70px;
    letter-spacing: -1.28px;
  }

  .hero-left {
    width: 50%;
  }

  .hero-right {
    width: 50%;
  }

  .chart-left {
    width: 60%;
  }

  .chart-right > h1 {
    font-size: 35px;
    line-height: 40px;
  }

  .referral-section {
    gap: 10rem;
  }

  .referral-left {
    width: 60%;
  }

  .referral-right {
    width: 40%;
  }

  .submission-section > h1,
  .integration-section > h1 {
    font-size: 35px;
  }
}

@media (max-width: 1300px) {
  .hero-left > h1 {
    color: #141414;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -1.28px;
    text-transform: capitalize;
  }

  .hero-left {
    width: 45%;
  }

  .hero-right {
    width: 55%;
  }

  .hero-left > p {
    color: #545454;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.4px;
    text-transform: capitalize;
    margin: 1rem 0;
  }

  .submission-section > h1 {
    font-size: 28px;
    line-height: 35px;
  }

  .submission-section > p {
    width: 70%;
  }

  .integration-section > h1 {
    font-size: 38px;
    line-height: 44px;
    width: 50%;
  }
}

@media (max-width: 1100px) {
  .hero-left {
    width: 50%;
  }

  .hero-right {
    width: 50%;
  }

  .hero-button-container > a:first-child > button {
    border-radius: 8px;
    background: linear-gradient(to right, #240037, #9405e4);
    color: white;
    padding: 5px 1rem;
  }

  .referral-left > h1 {
    font-size: 28px;
    line-height: 45px;
  }

  .integration-section > h1 {
    width: 100%;
  }

  .integration-section > button {
    width: 291px;
    padding: 20px 35px;
    border-radius: 8px;
    font-size: 20px;
    font-style: normal;
    line-height: 25px;
  }
}

@media (max-width: 1000px) {
  .hero,
  .chart-section,
  .referral-section {
    display: flex;
    align-items: center;
    padding: 5rem 8%;
    margin: auto;
    gap: 5rem;
    flex-direction: column;
  }

  .hero-left,
  .chart-left,
  .referral-left {
    width: 100%;
  }

  .hero-right,
  .chart-right,
  .referral-right {
    width: 70%;
  }
}

@media (max-width: 800px) {
  .hero-right {
    width: 60%;
  }

  .chart-right {
    width: 100%;
  }

  .chart-left {
    width: 70%;
  }

  .referral-right {
    width: 70%;
  }

  .integration-section > h1 {
    font-size: 25px;
  }

  .integration-section > a {
    width: 250px;
    padding: 15px 35px;
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 600px) {
  .links-container > a {
    color: #333;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }

  .integration-properties-container {
    flex-direction: column;
  }

  .referral-section {
    gap: 1rem;
  }

  .submission-section > p {
    width: 100%;
  }
}
