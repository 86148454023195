.main-container {
  display: flex;
}

.main {
  width: 85%;
  padding: 2rem;
  background-color: rgb(243, 244, 246);
}

.main>h2 {
  margin: 2rem 0;
  text-align: center;
  color: #6b6b6b;
}

.heading {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1rem 0;
}

.current-plan-container-main {
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #dbdbdb;
  padding: 2rem;
}

.current-plan-container-main>h3 {
  color: #75787c;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.current-plan-container {
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
}

.plan-container-left {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.plan-container-left>h1 {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2rem;
}

.plan-container-left>p {
  color: #575757;
}

.plan-container-left>p>span {
  font-weight: bold;
}

.plan-active-container {
  background-color: #f3f4f6;
  padding: 5px 10px;
  border-radius: 8px;
  width: fit-content;
  color: #4b5563;
}

.plan-active-container span {
  font-weight: bold;
}

.plan-container-middle {
  background-color: #e2e2e2;
  width: 1px;
  border-radius: 10px;
}

.plan-container-right {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding: 1rem 0;
}

.plan-container-right>p {
  color: #75787c;
}

.users-waitlist-container {
  display: flex;
  justify-content: space-between;
}

.users-waitlist-container>h4 {
  color: #75787c;
  font-weight: bold;
}

.progress-bar-container {
  background-color: #e0e0e0;
  border-radius: 10px;
  width: 100%;
  height: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  width: 0%;
  background-color: greenyellow;
  border-radius: 10px;
}

.all-plans-container {
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #dbdbdb;
  padding: 2rem;
  display: flex;
  gap: 1rem;
}

.plan-container {
  background-color: #f8f8f8;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.plan-container>h2 {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #374151;
}

.plan-container>div>h1 {
  font-weight: 600;
  font-size: 200%;
  line-height: 2.5rem;
  margin-top: 1rem;
}

.plan-container>div>h1>span {
  font-size: 1.5rem;
  line-height: 2rem;
}

.plan-container>p {
  color: #4b5563;
}

.buy-button-container {
  margin: 1rem 0;
  text-align: center;
}

.buy-button-container button {
  background: linear-gradient(to right, #240037 0%, #9405e4 100%);
  width: 100%;
  padding: 8px 10px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
}

.buy-button-container button:disabled {
  background: #75787c;
  cursor: not-allowed;
}

.buy-button-container>p {
  margin-top: 15px;
  color: #9ca3af;
  font-size: 0.95rem;
  line-height: 1rem;
}

.plan-container>h4 {
  font-weight: 600;
  color: #374151;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.features-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.feature-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.planLifeUp {
  display: flex;
  justify-content: center;
  border: 1px solid rgb(123, 123, 123);
  max-width: 410px;
  margin: 10px auto;
  padding: 6px;
  border-radius: 6px;
}

.planLifeUp li .selButtonUp {
  background: linear-gradient(to right, #240037 0%, #9405e4 100%);
  width: 100%;
  padding: 10px 40px;
  color: white;
  border-radius: 5px;
}

.planLifeUp li button {
  width: 100%;
  padding: 10px 40px;
  border-radius: 5px;
}

@media (max-width: 1500px) {
  .all-plans-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1300px) {
  .main {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .main-container {
    flex-direction: column;
  }

  .main {
    width: 100%;
    height: fit-content;
  }

  .planLifeUp {
    max-width: 240px;
    margin: 20px auto;
  }

  .planLifeUp li .selButtonUp {
    padding: 8px 12px;
    color: white;
    border-radius: 5px;
  }

  .planLifeUp li button {
    padding: 8px 12px;
  }
}

@media (max-width: 650px) {
  .all-plans-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .current-plan-container {
    flex-direction: column;
    gap: 10px;
  }

  .plan-container-middle {
    display: none;
  }
}