@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@400;500;600;700&family=Kanit&family=Neucha&family=Roboto:wght@100;400;500&family=Xanh+Mono&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  font-family: 'Figtree', sans-serif;

  /* color */
  --primary-color-1: #7A7A7A;
  --primary-color-2: #CCCCCC;
  --primary-color-3: #DDFF00;

  /* background color */
  --primary-bg-1: #1c1a27;
  --primary-bg-2: rgba(23, 23, 23, 0.6);
  --primary-bg-3: #0d0d0d;
  --primary-bg-4: linear-gradient(180deg,#1f1f1f 0%,rgb(20,20,20) 100%);

  /* border color */
  --primary-border-1: rgb(38, 38, 38);
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #858585;
}
::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 3px;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.heading-text-bg {
  background: linear-gradient(90deg, #b45ff1 0%, #9605e7 28.13%, #240037 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes slide {
  0% { transform: translateX(0); }
  20% { transform: translateX(-112%); }
  40% { transform: translateX(-224%); }
  60% { transform: translateX(-336%); }
  80% { transform: translateX(-448%); }
  100% { transform: translateX(-560%); }
}

.q_slider {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  place-items: center;
  margin: 0px;
  padding: 0px;
  width: 100vw;
  overflow-x: hidden;
  list-style-type: none;
  gap: 24px;
  position: relative;
  flex-direction: row;
  will-change: transform;
  margin-top: 32px;
}

.q_slider_card{
  animation: slide 15s linear infinite; 
}

img{
  max-width: unset;
}

.q_slider_img {
  width: 200px !important;
}
