.plans {
  min-height: 80vh;
  max-height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.all-plans-container {
  background-color: white;
  border-radius: 0.5rem;
  padding: 2rem;
  display: flex;
  gap: 1rem;
  width: 85%;
  margin: auto;
}

.plan-container {
  background-color: #f8f8f8;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.plan-container>h2 {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #374151;
}

.plan-container>div>h1 {
  font-weight: 600;
  font-size: 200%;
  line-height: 2.5rem;
  margin-top: 1rem;
}

.plan-container>div>h1>span {
  font-size: 1.5rem;
  line-height: 2rem;
}

.plan-container>p {
  color: #4b5563;
}

.buy-button-container {
  margin: 1rem 0;
  text-align: center;
}

.buy-button-container button {
  background: linear-gradient(to right, #240037 0%, #9405e4 100%);
  width: 100%;
  padding: 8px 10px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
}

.buy-button-container button:disabled {
  background: #75787c;
  cursor: not-allowed;
}

.buy-button-container>p {
  margin-top: 15px;
  color: #9ca3af;
  font-size: 0.95rem;
  line-height: 1rem;
}

.plan-container>h4 {
  font-weight: 600;
  color: #374151;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.features-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.feature-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.footer-container {
  margin-top: 5vh;
}

.planLife {
  display: flex;
  justify-content: center;
  border: 1px solid black;
  max-width: 410px;
  margin: 0 auto;
  padding: 6px;
  border-radius: 6px;
}

ul li .selButton {
  background: linear-gradient(to right, #240037 0%, #9405e4 100%);
  width: 100%;
  padding: 10px 40px;
  color: white;
  border-radius: 5px;
}

ul li button {
  padding: 10px 40px;
  border-radius: 5px;
}

@media (max-width: 1500px) {
  .all-plans-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 850px) {
  .all-plans-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .current-plan-container {
    flex-direction: column;
    gap: 10px;
  }

  .plan-container-middle {
    display: none;
  }

  .planLife {
    max-width: 240px;
    margin: 20px auto;
  }

  ul li .selButton {
    padding: 8px 12px;
    color: white;
    border-radius: 5px;
  }

  ul li button {
    padding: 8px 12px;
  }
}

@media (max-width: 500px) {
  .all-plans-container {
    width: 100%;
  }
}