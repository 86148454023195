.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2e5ff;
  padding: 5rem 0;
}

.form-container-main {
  width: 25rem;
  background-color: white;
  border-radius: 10px;
  padding: 3rem;
  padding-top: 0px;
  box-shadow: 5px 3px 15px #989898;
  border: 1px solid silver;
  overflow-y: auto;
  max-height: 100%;
  height: fit-content;
}

.heading-container {
  padding-top: 3rem;
  /* position: sticky; */
  top: 0;
  /* background-color: white; */
}

.heading {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 2rem;
  background: linear-gradient(90deg, #b45ff1 0%, #9605e7 28.13%, #240037 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.action-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.action-title {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.action-container > input {
  padding: 10px;
  border-radius: 8px;
  outline-color: rgb(201, 201, 201);
  background-color: #f1f1f1;
}

.submit-button {
  padding: 10px;
  border-radius: 8px;
  background: linear-gradient(to right, #240037 0%, #9405e4 100%);
  color: white;
  width: 100%;
}

.loading-container {
  height: 100px;
  width: 100px;
  align-self: center;
}

.submit-loading-overlay {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(114, 114, 114, 0.11);
}

.submit-loading-container {
  background-color: rgba(255, 255, 255, 0.678);
  border-radius: 10px;
  padding: 1rem;
  border: 1px solid rgba(192, 192, 192, 0.61);
}

.submit-loading-container > h1 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1rem 0;
}

@media (max-width: 650px) {
  .main {
    padding: 1rem;
  }

  .form-container-main {
    width: 100%;
  }
}
