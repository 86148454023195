.main-container {
  display: flex;
}

.main {
  width: 85%;
  padding: 2rem;
  padding-top: 5rem;
  display: flex;
  justify-content: space-between;
  background-color: rgb(243, 244, 246);
  gap: 1rem;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 1.5rem;
  width: 60%;
  transition: 100ms;
  height: fit-content;
}

.form-container > h1 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input-container > p {
  color: rgb(75 85 99);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  display: flex;
}

.input-container > input {
  outline: none;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid rgb(209 213 219);
  padding: 12px;
}

.action-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.action-title {
  color: rgb(75 85 99);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.action-caption {
  color: rgb(107 114 128);
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
}

.action-input-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid rgb(209 213 219);
  padding: 12px;
  outline: none;
  gap: 10px;
}

.action-input-container:has(input:focus) {
  border: 1px solid grey;
}

.action-input-container > input {
  outline: none;
  width: 100%;
}

.delete-btn {
  width: 20px;
  cursor: pointer;
}

.delete-btn > img {
  width: 100%;
  object-fit: contain;
}

.form-button-container {
  display: flex;
  justify-content: space-between;
}

.submit-btn {
  background: linear-gradient(to right, #240037 0%, #9405e4 100%);
  font-weight: bold;
  cursor: pointer;
  border-radius: 0.375rem;
  color: white;
  padding: 0.5rem 1rem;
}

.add-btn {
  cursor: pointer;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  background-color: #793FDF;
  color: white;
  font-weight: bold;
}

.overlay {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: rgba(197, 197, 197, 0.151);
}

.loading-container {
  background-color: rgba(255, 255, 255, 0.678);
  padding: 1rem 2rem;
  border-radius: 10px;
  border: 1px solid rgb(224, 224, 224);
  text-align: center;
}

.loading-container > h1 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
}

.form > h4 {
  margin-top: 10px;
}

@media (max-width: 1300px) {
  .main {
    width: 80%;
  }
}

@media (max-width: 1100px) {
  .form-container {
    width: 70%;
  }

  .main {
    flex-direction: column;
  }
}

@media (max-width: 900px) {
  .form-container {
    width: 70%;
  }
}

@media (max-width: 800px) {
  .main-container {
    flex-direction: column;
  }

  .main {
    width: 100%;
    height: fit-content;
  }

  .form-container {
    width: 100%;
  }
}
