.container {
  min-width: 15rem;
  max-width: 15%;
  background-color: white;
  border-radius: 1.5rem;
  padding: 1rem;
  max-height: 80vh;
  height: fit-content;
  overflow-y: auto;
  position: sticky;
  top: 10px;
}

.heading {
  font-weight: 700;
  font-size: 1rem;
  color: #9405e4;
  text-align: center;
  animation: wave 1s infinite;
}


.button-85 {
  padding: 10px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.templates-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.template-container {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 10px;
  gap: 1rem;
  box-shadow: 1px 1px 5px silver;
}

.template-container > img {
  object-fit: contain;
  width: 30px;
  border-radius: 0.5rem;
}

.template-container > p {
  text-align: left;
  font-size: 15px;
}

.template-container[data-selected='true'] {
  box-shadow: 1px 1px 5px violet;
}

.template-container[data-selected='true'] > p {
  color: #9405e4;
}

.overlay {
  position: fixed;
  inset: 0;
  z-index: 1;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.089);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.modal {
  background-color: white;
  width: 30rem;
  border-radius: 10px;
  padding: 2rem 3rem;
  max-width: fit-content;
  position: relative;
}

.modal > form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.modal input {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  padding: 10px;
  background-color: rgb(243 244 246);
  outline: none;
  width: 100%;
}

.modal > form > button {
  background: linear-gradient(to right, #240037 0%, #9405e4 100%);
  border-radius: 0.375rem;
  color: white;
  padding: 0.5rem;
  width: fit-content;
}

.modal > form > button:disabled {
  cursor: not-allowed;
  background: silver;
  color: black;
}

.close-btn {
  width: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.close-btn > img {
  width: 100%;
  object-fit: contain;
}

@media (max-width: 1100px) {
  .container {
    width: 100%;
    max-width: none;
    height: fit-content;
  }

  .templates-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .template-container {
    width: 180px;
  }

  .button-85{
    width: 180px;
  }
}

@media (max-width: 450px) {
  .template-container {
    width: 100%;
  }

  .button-85{
    width: 100%;
  }
}
