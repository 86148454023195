.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2e5ff;
}

.form-container-main {
  width: 30rem;
  background-color: white;
  border-radius: 10px;
  padding: 3rem 5rem;
  text-align: center;
  box-shadow: 5px 3px 15px #989898;
  border: 1px solid silver;
}

.heading {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  margin: 1rem 0;
}

.caption {
  color: rgb(75 85 99);
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin: 0.5rem 0;
}

.caption > span {
  font-weight: bold;
  color: black;
}

.line {
  margin: 1rem 0;
}

.form-container-main > h3 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: rgb(17 24 39);
  font-weight: 600;
  margin: 1rem 0;
}

.share-link-container {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #f3f4f6;
  margin: 1rem 0;
}

.share-caption {
  color: rgb(75 85 99);
  font-size: 0.9rem;
  line-height: 1.75rem;
  margin: 0.5rem 0;
}

.social-share-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.share-link-container {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: rgb(243 244 246);
}

.share-link-container > p {
  width: 100%;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: auto;
  color: rgb(55 65 81);
}

.loading-container {
  height: 100px;
  width: 100px;
  margin: auto;
}

@media (max-width: 650px) {
  .main {
    padding: 1rem;
  }

  .form-container-main {
    width: 100%;
  }
}
