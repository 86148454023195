.main-container {
  display: flex;
}

.main {
  width: 100%;
  padding: 2rem;
  background-color: rgb(243, 244, 246);
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5rem;
  gap: 5rem;
}

.container-left,
.container-right {
  border-radius: 1rem;
  background-color: white;
  padding: 2rem;
  width: 500px;
}

.container-left > h1,
.container-right > h1 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
}

.form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.input {
  width: 100%;
  outline: none;
  border-radius: 0.375rem;
  border: 1px solid rgb(209 213 219);
  padding: 12px;
}

.input-split-container {
  display: flex;
  gap: 1rem;
}

.submit-button {
  border-radius: 0.375rem;
  background: linear-gradient(to right, #51007c, #9405e4);
  color: white;
  padding: 12px;
  cursor: pointer;
}

.submit-button:disabled {
  cursor: not-allowed;
  background: rgb(138, 138, 138);
}

.container-right {
  width: 350px;
}

.container-right > h1 {
  margin-bottom: 5px;
}

.container-right > p {
  font-size: 14px;
  font-weight: 400;
  color: grey;
  margin-bottom: 1rem;
}

.plan-features > h4 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.features-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.feature-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media (max-width: 1250px) {
  .container {
    align-items: center;
    margin-top: 5rem;
    gap: 5rem;
    flex-direction: column;
  }

  .container-left,
  .container-right {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .main-container {
    flex-direction: column;
  }

  .main {
    height: fit-content;
  }
}
